import {
    AddStaffData,
    DoctorDetailData,
    DoctorStaffData,
    Staff,
    User,
} from '@typing/global';
import axios from 'axios';
import envConfig from '../../environment';
import storage from '@utils/storage';

export const addStaff = (data: AddStaffData): Promise<User> => {
    return axios.post(`${envConfig.apiUrl}/staff/add-staff`, data, {
        headers: {
            authorization: `Bearer ${storage.getToken()}`,
            Accept: 'application/json',
        },
    });
};

export const getStaffs = ({
    search = '',
    sort = 'createdAt',
    order = 'ASC',
    page = 1,
    limit = 10,
}): Promise<User> => {
    return axios.get(
        `${envConfig.apiUrl}/staff/list?search=${search}&sort=${sort}&order=${order}&page=${page}&limit=${limit}`,
        {
            headers: {
                authorization: `Bearer ${storage.getToken()}`,
                Accept: 'application/json',
            },
        }
    );
};

export const addDoctorStaff = (data: DoctorStaffData): Promise<Staff> => {
    return axios.post(`${envConfig.apiUrl}/doctor/add-doctor-staff`, data, {
        headers: {
            authorization: `Bearer ${storage.getToken()}`,
            Accept: 'application/json',
        },
    });
};

export const getDoctorsForStaff = (): Promise<User> => {
    return axios.get(`${envConfig.apiUrl}/staff/doctors/list`, {
        headers: {
            authorization: `Bearer ${storage.getToken()}`,
            Accept: 'application/json',
        },
    });
};

export const doctorsDetailsForStaff = (
    data: DoctorDetailData
): Promise<any> => {
    return axios.post(`${envConfig.apiUrl}/staff/doctor`, data, {
        headers: {
            authorization: `Bearer ${storage.getToken()}`,
            Accept: 'application/json',
        },
    });
};
