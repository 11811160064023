import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import AppContextProvider from './contexts/AppContextProvider';
import { QueryClientProvider } from 'react-query';
import AppRoutes from './Routes';
import { queryClient } from './lib/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FullPageSpinner } from '@components/Common/Spinner/FullPageSpinner';
//import { ReactQueryDevtools } from 'react-query/devtools';

(async () => {
    const root = createRoot(document.getElementById('root'));

    const AppContent = (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={<FullPageSpinner />}>
                <AppContextProvider>
                    <Router>
                        <AppRoutes />
                    </Router>
                    {/*<ReactQueryDevtools />*/}
                    <ToastContainer />
                </AppContextProvider>
            </Suspense>
        </QueryClientProvider>
    );

    root.render(
        process.env.NODE_ENV === 'development' ? (
            <React.StrictMode>{AppContent}</React.StrictMode>
        ) : (
            AppContent
        )
    );
})();
