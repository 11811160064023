import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import logo from '@images/logo.svg';
import NoImage from '@images/no-image.png';
import downarrow from '@images/down-arrow.svg';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications'; // Notification icon import
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../contexts/AppContextProvider';
import useOnClickOutside from '@hooks/useOnClickOutside';
import {
    Badge,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { socket } from '@utils/socket/socket';
import { getNotificationsCount } from '../../../API/Notification';
import { doctorsDetailsForStaff, getDoctorsForStaff } from '../../../API/Staff';
import { failed } from '../Toastify';
import storage from '@utils/storage';
import { queryClient } from '../../../lib/react-query';
import { logout } from '../../../API/Auth';

interface ProtectedLayoutProps {
    children?: React.ReactNode;
    handleMenuToggle: () => void;
}

const Header: React.FC<ProtectedLayoutProps> = ({ handleMenuToggle }) => {
    const { value, setValue } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [doctors, setDoctors] = useState<any[]>([]); // State to store doctors list
    const [selectedDoctor, setSelectedDoctor] = useState<any>(null); // State to store selected doctor
    const navigate = useNavigate();
    const [profileImage, setProfileImage] = useState(
        value?.user?.profileImage || null
    );
    const ref = useRef(null);
    const userId = value.user?.userId;

    useEffect(() => {
        if (userId) {
            getNotificationsCount().then((res: any) => {
                setNotificationsCount(res.data);
            });
            // Listen for notificationsCount from the server
            socket.on('notificationsCount', (count) => {
                setNotificationsCount(count);
            });

            return () => {
                socket.off('notificationsCount');
            };
        }
    }, [userId]);

    const fetchDoctorsForStaff = () => {
        getDoctorsForStaff()
            .then((res: any) => {
                const doctorsList = res?.data?.data || [];
                setDoctors(doctorsList); // Set doctors from response
            })
            .catch((error) => {
                console.error(error); // Handle the error
            });
    };

    const fetchDoctorDetails = async (id: number) => {
        doctorsDetailsForStaff({ id })
            .then((res: any) => {
                // Update the context with the selected doctor's data
                const updatedContextValue = {
                    ...value,
                    user: {
                        ...value?.user,
                        doctorType: res?.data?.data?.doctorType,
                        staffSelectedDoctorId: id,
                    },
                };
                storage.setUser(updatedContextValue.user);
                setValue(updatedContextValue); // Update context
                queryClient.invalidateQueries();
            })
            .catch((error) => {
                failed(error.response?.data?.message);
            });
    };

    useEffect(() => {
        if (value?.user?.userType === 'staff') {
            fetchDoctorsForStaff();
            setSelectedDoctor(value.user?.staffSelectedDoctorId);
        }
    }, [value?.user?.userType]);

    useEffect(() => {
        setProfileImage(value?.user?.profileImage);
    }, [value?.user?.profileImage]);

    const handleClickOutside = () => {
        setIsOpen(false);
    };

    useOnClickOutside(ref, handleClickOutside);

    const handleLogout = () => {
        logout();
    };

    const navigateToNotification = () => {
        navigate('/app/notification');
    };

    const handleDoctorChange = (e: any) => {
        const doctorId = e.target.value;
        setSelectedDoctor(doctorId); // Set the selected doctor in the state
        fetchDoctorDetails(doctorId); // Optionally fetch additional details for the selected doctor
    };

    return (
        <div className="main-header-div">
            <div className="main-header-flex">
                <div className="logo-main-div">
                    <NavLink rel="stylesheet" to="/app/dashboard">
                        <img src={logo} alt="logo" className="logo-icon" />
                    </NavLink>
                    <MenuIcon
                        className="menu-icon"
                        onClick={handleMenuToggle}
                    />
                    <h4>EYE REFER</h4>
                </div>

                {/* Dropdown for selecting doctor */}
                {value?.user?.userType === 'staff' && doctors.length > 0 ? (
                    <div className="doctor-dropdown">
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="doctor-select-label">
                                Selected Doctor
                            </InputLabel>
                            <Select
                                value={selectedDoctor}
                                label="Selected Doctor"
                                onChange={handleDoctorChange}
                            >
                                {doctors?.map((doctor) => (
                                    <MenuItem
                                        key={doctor.id} // Ensure each item has a unique key
                                        value={doctor.id}
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {doctor.fullName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                ) : null}

                <div className="header-profile">
                    <div
                        className="notification-icon"
                        onClick={navigateToNotification}
                    >
                        <Badge
                            badgeContent={notificationsCount}
                            color="primary"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </div>

                    <div
                        className="dropdown"
                        ref={ref}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <button className="dropdown-button">
                            <div className="inner-wrapper-profile">
                                <img
                                    src={profileImage || NoImage}
                                    alt="profile"
                                    className="profile-icon"
                                    height={40}
                                    width={40}
                                />
                                <div className="profile-content">
                                    <h4 style={{ textTransform: 'capitalize' }}>
                                        Hi, {value?.user?.firstName}{' '}
                                        {value?.user?.lastName}
                                    </h4>
                                    <p>Welcome Back</p>
                                </div>
                                <div className="dropdown-img">
                                    <img src={downarrow} alt="profile" />
                                </div>
                            </div>
                        </button>
                        <div
                            className={`dropdown-content ${
                                isOpen ? 'show' : ''
                            }`}
                        >
                            {value?.user?.userType === 'doctor' ? (
                                <NavLink to="/app/profile">Profile</NavLink>
                            ) : null}
                            {value?.user?.userType === 'doctor' ? (
                                <NavLink to="/app/change-password">
                                    Change Password
                                </NavLink>
                            ) : null}
                            <NavLink
                                to="/auth/login"
                                replace={true}
                                onClick={handleLogout}
                                style={{ display: 'flex' }}
                            >
                                <p style={{ marginRight: '1rem' }}>Logout</p>
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="#232A2E"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.30775 17.5C1.80258 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.1974 0.5 15.6923V2.30775C0.5 1.80258 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H9.0095V2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V15.6923C2 15.7692 2.03208 15.8398 2.09625 15.9038C2.16025 15.9679 2.23075 16 2.30775 16H9.0095V17.5H2.30775ZM13.2308 13.2692L12.1923 12.1845L14.627 9.75H6.09625V8.25H14.627L12.1923 5.8155L13.2308 4.73075L17.5 9L13.2308 13.2692Z"
                                        fill=""
                                    />
                                </svg>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
